/* SidePanel.css */

.side-panel {
    position: fixed;
    top: 0;
    left: -20rem;
    width: 20rem;
    height: 100%;
    background-color: var(--md-sys-color-surface-container);
    transition: left 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.side-panel.open {
    left: 0;
}

.side-panel-close-button {
    border-bottom: 1px solid var(--md-sys-color-on-surface);
    text-align: left;
}

.side-panel-selection {
    display: flex;
    flex-direction: column;
    flex: 1; /* Allows this section to grow and fill available space */
    margin-bottom: 1rem;
    overflow: hidden; /* Prevents overflow from affecting the layout */
}

.side-panel-selection h3 {
    margin: 0.5rem;
    color: var(--md-sys-color-primary);
}

.side-panel-selection ul {
    flex: 1; /* Makes the list take up remaining space */
    padding: 0;
    margin: 0;
    list-style: none; /* Removes default list styling */
}

.side-panel-selection li {
    align-items: center;
    border-bottom: 1px solid var(--md-sys-color-on-surface);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    min-height: 2.5rem;
    padding: 0.25rem 1rem;
    position: relative;
}

.side-panel-selection li:hover,
.side-panel-selection li.selected {
    background-color: var(--md-sys-color-surface-container-high);
}

.settings-button {
    padding-bottom: 1rem;
    border-top: 1px solid var(--md-sys-color-on-surface); /* Optional: Adds a separator */
}

.settings-button li {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.settings-button md-icon {
    margin: 0.75rem;
}

.inline-flex-container {
    display: inline-flex;
    align-items: center;
}

.side-panel .settings-form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.side-panel .settings-form md-filled-text-field,
.side-panel .settings-form md-filled-select,
.side-panel .invite-friend-dialog md-filled-text-field,
.side-panel .invite-friend-dialog md-filled-select {
    min-width: 100%;
}

/* Ensure the 'selected' class is properly styled */
.side-panel .selected {
    background-color: var(--md-sys-color-surface-container-high);
}

/* Additional generic styles */
.side-panel h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* Ensure the settings button aligns properly */
.app-side-panel .settings-button li {
    justify-content: flex-start;
    min-height: 3rem;
    display: flex;
    align-items: center;
}

.app-side-panel .settings-button md-icon {
    margin-right: 0.75rem;
}

/* Optional: Additional styles specific to ConversationSidePanelContent */
.app-side-panel .inline-flex-container {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.settings-dialog md-dialog {
    /* Add any specific styles needed for the settings dialog */
}

.settings-dialog .headline,
.invite-friend-dialog .headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings-dialog .dialog-close-button {
    /* Style the close button within the dialog */
}

.settings-dialog .settings-form,
.invite-friend-dialog .invite-friend-form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.settings-dialog .settings-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin: 0 0.5rem;
}
.invite-friend-dialog .button-group {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}
